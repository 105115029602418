import React, {Component} from 'react';
import {withApollo} from 'react-apollo';
import moment from 'moment';
import findLast from 'lodash/findLast';

import Message from '../Message/v2.js';
import ScrollContainer from '../../../../common/atoms/ScrollContainer';

import noticeListStyles from '../../../NoticeSection.module.css';

import updateUserChannelViewedGql from '../../../../../gql/update-user-channel-viewed';
import updateChannelViewedCache from '../../../../../data/update-channel-viewed-cache';
import {EmptyList, FlightIcon, PacMan} from "./style";
import {ReactComponent as FlightIconSVG} from "../../../../../images/icons/flight.svg";
import Pac from "../../../../../images/pac.gif";
import LoadingPage from "../../../../common/molecules/LoadingPage";
import Divider from "../../../../common/molecules/Divider";
import ChannelType from "../../../../../constants/Channel";
import NoticeType, {TYPE} from "../../../../../constants/Notice";
import PropTypes from "prop-types";
import {shouldMessageShowAuthor, sortNotice} from "../../../../../utils/notice-utils";
import PTSStepType from "../../../../../constants/PTSStep";
import PTSStep from "../../../../flight/FlightDetail/FlightBody/ScheduleTab/PTSStep";

const retro = Math.random() <= 0.01;

class MessageList extends Component {

  previousPTSRef = React.createRef();
  currentPTSRef = React.createRef();

  constructor(props) {
    super(props);

    const {
      channel,
      nodes,
    } = props;

    // We need to capture the notice above where we'll show the NEW notice bar
    // here so we can persist it until the user leaves the current flight/terminal chat
    const lastViewed = channel.user_last_viewed;
    // const lastMessage = this.filterAndSortMessages(channel.messages).find(m => !lastViewed || m.created_at > lastViewed);
    const lastMessage = nodes.find(m => !lastViewed || m.created_at > lastViewed);

    this.state = {
      loading: false,
      initialOldestUnviewedMessageId: lastMessage ? lastMessage.id : null // Keep this in state! Don't move to props. @Chris
    };
  }

  render = () => {

    const {
      shouldShowDelta,
      shouldShowFlightIcon,
    } = this.props;

    return (
      <ScrollContainer
        className={`${shouldShowDelta ? noticeListStyles.timeline : ''} ${noticeListStyles.section}`}
        parentScrolled={this.parentScrolled}
        scroll='up'
      >
        {
          shouldShowFlightIcon && retro && this.renderPacMan()
        }
        {
          shouldShowFlightIcon && !retro && this.renderFlightIcon()
        }
        {this.renderMessages()}
        {this.renderFuturePTS()}
      </ScrollContainer>
    );
  };

  renderFlightIcon = () => {
    const {
      flightIconPositionY,
    } = this.state;

    return (
      <FlightIcon y={flightIconPositionY}>
        <FlightIconSVG/>
      </FlightIcon>
    )
  };

  renderPacMan = () => {
    const {
      flightIconPositionY,
    } = this.state;

    return (
      <PacMan y={flightIconPositionY}>
        <img src={Pac} alt=""/>
      </PacMan>
    )
  };

  renderMessages = () => {
    const {
      nodes,
      terminalOffset,
      shouldShowDelta,
      shouldMessageFade,
      isChannelAdmin,
    } = this.props;

    const {
      loading,
      initialOldestUnviewedMessageId
    } = this.state;

    // const filteredNodes = this.filterAndSortMessages(messages); // Only Completable & Not Completed event should be shown
    const filteredNodes = nodes.sort(sortNotice);

    const lastEventOrEscalation = findLast(filteredNodes, event => !event.deleted_at && (event.messageType === TYPE.EVENT || event.messageType === TYPE.ESCALATION));
    this.lastCompletedPTS = findLast(filteredNodes, event => (event.messageType === TYPE.PTS));

    if (filteredNodes.length === 0) {
      return <EmptyList>No messages</EmptyList>;
    }

    const now = moment.utc().utcOffset(terminalOffset);

    const messageList = [];
    let lastDate;
    let previousOwner = -1;
    for (let i = 0; i < filteredNodes.length; i++) {
      const notice = filteredNodes[i];

      const isNew = notice.id === initialOldestUnviewedMessageId;

      const dateSent = moment.utc(notice.completed_at || notice.created_at).utcOffset(terminalOffset);
      const isFirstInDate = (!lastDate || dateSent.isAfter(lastDate, 'day'));

      if (isFirstInDate || isNew) {
        messageList.push(
          <Divider head={isNew ? 'NEW' : null}
                   body={isFirstInDate ? this.formatDateDisplay(dateSent, now) : null}
                   primary={isNew}
                   key={`bar-${notice.id}`}/>
        );
      }
      lastDate = dateSent;

      if (i === 0 && loading) {
        messageList.push(<LoadingPage key={"Loader"}/>)
      }

      let ref;
      if (this.lastCompletedPTS && notice.id === this.lastCompletedPTS.id) {
        ref = this.previousPTSRef;
      }

      // TODO Handle at ingestion
      if (notice.content && notice.content.includes("fuel total")) {
        break;
      }

      messageList.push(
        <Message key={notice.id}
                 message={notice}
                 shouldShowDelta={shouldShowDelta}
                 shouldFade={shouldMessageFade}
                 shouldShowAuthor={shouldMessageShowAuthor(notice, previousOwner)}
                 isLastEvent={lastEventOrEscalation ? lastEventOrEscalation.id === notice.id : false}
                 isChannelAdmin={isChannelAdmin}
                 terminalOffset={terminalOffset}
                 ref={ref}
        />
      );
      previousOwner = notice.deleted_at ? null : (notice.created_by ? notice.created_by.id : null);
    }

    return messageList;
  };

  renderFuturePTS = () => {
    const {
      pts,
      terminalOffset,
    } = this.props;
    if (!pts || pts.length === 0) return [];

    const ptsList = [];
    const currentPTS = pts[0];
    const isDisrupted = currentPTS.dueTime ? currentPTS.dueTime.diff(moment(), 'minutes') <= 1 : true;

    this.currentPTS = currentPTS;
    pts.forEach((pts, i) => {

      let ref;
      if (pts.code === currentPTS.code) {
        ref = this.currentPTSRef;
      }

      ptsList.push(
        <PTSStep
          key={pts.code}
          pts={pts}
          isFirst={i === 0}
          isDisrupted={isDisrupted}
          terminalOffset={terminalOffset}
          ref={ref}/>
      )
    });

    return ptsList;
  };

  componentDidMount() {
    this.interval = setInterval(() => this.updateFlightIcon(), 3000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateFlightIcon();
  }

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  updateFlightIcon = () => {

    const {
      flightIconPositionY
    } = this.state;

    if (this.currentPTSRef.current && this.previousPTSRef.current) {

      /* Get PTS Time Proportion */
      const endMoment = this.currentPTS.dueTime || moment();
      const startMoment = moment(this.lastCompletedPTS.created_at);
      const minuteDiff = moment.duration(endMoment.diff(startMoment)).asMinutes(); // Total Minutes between Last PTS completion time & current PTS dueTime
      const currentDiff = moment.duration(moment().diff(startMoment)).asMinutes(); // Minutes Left between now and current PTS dueTime.
      const proportion = minuteDiff < 0 ? 1 : Math.min((currentDiff / minuteDiff).toFixed(2), 1);

      /* Get Absolute Position */
      const secondLastEventY = this.previousPTSRef.current.offsetTop;
      const lastEventY = this.currentPTSRef.current.offsetTop;
      const newPositionY = secondLastEventY + (lastEventY - secondLastEventY) * proportion;

      if (!flightIconPositionY) {
        this.setState({
          flightIconPositionY: newPositionY
        });
      } else if (flightIconPositionY !== newPositionY) {
        this.setState({
          flightIconPositionY: newPositionY
        });
      }
    }
  };

  /* Formatting */
  formatDateDisplay = (date, now) => {
    if (date.isSame(now, 'day')) {
      return 'Today';
    } else if (date.isSame(now.clone().subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    } else {
      return date.format('ddd, DD/MM');
    }
  };

  /* ScrollView Logics */
  parentScrolled = (scroll) => {
    if (scroll.atTop) {
      this.triggerLoadMore();
    }

    if (scroll.atBottom) {
      this.triggerMessagesViewed();
    }
  };

  triggerLoadMore = async () => {

    const {
      channel,
      fetchMoreMessages,
    } = this.props;

    const {
      loading,
    } = this.state;

    if (loading) {
      return;
    }

    const cursor = channel.messages.before;
    if (!cursor) { return; }

    this.setState({ loading: true });
    try {
      await fetchMoreMessages(cursor);
    }
    finally {
      this.setState({ loading: false });
    }
  };

  triggerMessagesViewed = () => {
    const {
      client,
      channel,
    } = this.props;

    const {id, messages, user_last_viewed} = channel;

    const lastMessage = messages.nodes[messages.nodes.length - 1];

    if (!lastMessage || lastMessage.created_at <= user_last_viewed) return;

    const input = {
      notice_channel_id: id,
      last_viewed: lastMessage.created_at
    };

    client.mutate({
      mutation: updateUserChannelViewedGql,
      variables: { input },
      update: updateChannelViewedCache,

      optomisticResponse: {
        updateUserMessagesViewed: {
          __typename: "UserChannelViewedUpdated",
          ...input
        }
      }
    });
  };
}

MessageList.defaultProps = {
  nodes: [],
  shouldShowDelta: false,
  shouldShowFlightIcon: false,
  isChannelAdmin: false,
};
MessageList.propTypes = {
  channel: ChannelType.isRequired,
  nodes: PropTypes.arrayOf(NoticeType).isRequired,
  terminalOffset: PropTypes.number.isRequired,
  shouldShowDelta: PropTypes.bool,
  shouldShowFlightIcon: PropTypes.bool,
  shouldMessageFade: PropTypes.func.isRequired,
  pts: PropTypes.arrayOf(PTSStepType),
  isChannelAdmin: PropTypes.bool,
};

export default withApollo(MessageList);
